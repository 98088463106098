import { Link } from '@remix-run/react'
import cn from 'classnames'

import Logo from '@/components/icons/Logo'
import { useCurrent } from '@/current'

type Props = {
  isFullWidth?: boolean
  hideBanner?: boolean
}

export default function SiteHeader({ isFullWidth, hideBanner }: Props) {
  const { userSignedIn } = useCurrent()

  return (
    <>
      {!hideBanner && (
        <div className='bg-orange-500 px-3 py-1 text-center text-white'>
          <span className='text-yellow-300'>⚡</span>{' '}
          <span>Blazing fast NVMe drives with unlimited IOPS now available.</span>{' '}
          <span className='whitespace-nowrap'>
            <Link to='/blog/announcing-metal' className='whitespace-nowrap bg-white px-sm font-medium text-orange-500'>
              Read about PlanetScale Metal
            </Link>{' '}
            <span className='text-yellow-300'>⚡</span>
          </span>
        </div>
      )}
      <header className='relative mb-6 mt-4 bg-primary'>
        <div
          className={cn('flex flex-col gap-y-3 px-3 sm:px-5', {
            'container max-w-7xl': !isFullWidth
          })}
        >
          <div className='grid w-full grid-cols-[auto_1fr] grid-rows-1 md:grid-rows-2 md:gap-3 lg:grid-rows-1'>
            <Link
              aria-label='Go to homepage'
              className='col-start-1 col-end-2 h-4 w-4 rounded-full text-primary lg:hidden'
              prefetch='intent'
              to='/'
            >
              <Logo height={32} width={32} />
            </Link>

            <div className='group col-start-2 col-end-3 flex items-center justify-end gap-1.5 lg:row-start-1 lg:gap-3'>
              {userSignedIn ? (
                <a href='https://app.planetscale.com' className='font-semibold text-orange'>
                  Dashboard
                </a>
              ) : (
                <div className='flex items-center gap-1'>
                  <a
                    href='https://auth.planetscale.com/sign-in'
                    className='font-semibold text-primary hover:text-orange'
                  >
                    Sign in
                  </a>
                  <span className='text-decoration'>|</span>
                  <a href='https://auth.planetscale.com/sign-up' className='font-semibold text-orange'>
                    Get started
                  </a>
                </div>
              )}
            </div>

            <div className='col-start-1 col-end-3 flex items-center gap-x-3 lg:col-end-2 lg:row-start-1'>
              <Link
                aria-label='Go to homepage'
                className='col-start-1 col-end-2 hidden h-4 w-4 rounded-full text-primary lg:block'
                prefetch='intent'
                to='/'
              >
                <Logo height={32} width={32} />
              </Link>
              <nav aria-label='Main' data-orientation='horizontal' className='hidden items-center md:flex'>
                <SiteHeaderNavigation />
              </nav>
            </div>
          </div>

          <details className='md:hidden'>
            <summary>Navigation</summary>

            <nav className='dashed-box mt-1 p-3'>
              <SiteHeaderNavigation />
            </nav>
          </details>
        </div>
      </header>
    </>
  )
}

function SiteHeaderNavigation() {
  return (
    <ul className='flex flex-wrap gap-x-1 md:flex-nowrap'>
      <li>
        <Link className='font-semibold text-primary transition hover:text-contrast' prefetch='intent' to='/docs'>
          Documentation
        </Link>
      </li>
      <li className='text-decoration' role='presentation'>
        |
      </li>
      <li>
        <Link
          className='font-semibold text-primary transition hover:text-contrast'
          prefetch='intent'
          to='/case-studies'
        >
          Case studies
        </Link>
      </li>
      <li className='text-decoration' role='presentation'>
        |
      </li>
      <li>
        <Link className='font-semibold text-primary transition hover:text-contrast' prefetch='intent' to='/features'>
          Features
        </Link>
      </li>
      <li className='text-decoration' role='presentation'>
        |
      </li>
      <li>
        <Link className='font-semibold text-primary transition hover:text-contrast' prefetch='intent' to='/blog'>
          Blog
        </Link>
      </li>
      <li className='text-decoration' role='presentation'>
        |
      </li>
      <li>
        <Link className='font-semibold text-primary transition hover:text-contrast' prefetch='intent' to='/metal'>
          Metal
        </Link>
      </li>
      <li className='text-decoration' role='presentation'>
        |
      </li>
      <li>
        <Link className='font-semibold text-primary transition hover:text-contrast' prefetch='intent' to='/pricing'>
          Pricing
        </Link>
      </li>
      <li className='text-decoration' role='presentation'>
        |
      </li>
      <li>
        <Link className='font-semibold text-primary transition hover:text-contrast' prefetch='intent' to='/contact'>
          Contact
        </Link>
      </li>
    </ul>
  )
}
